<template>
  <div class="EditSection mb-4">
    <div class="flex justify-between mb-4">
      <div class="flex items-center">
        <BackButton :href="`/admin/gamification/leaderboards`" :cssClass="'mr-4'" />
        <h2 class="text-2xl font-bold">{{ leaderboard.name }}</h2>
      </div>
      <div class="ToggleContainer field flex items-center field--checkbox gap-2">
        <Transition name="fade">
          <button @click.prevent="updateActive(active)" v-if="isActiveChanged && active !== initialActive" :class="['Btn', {'Btn--Save': active, 'Btn--Delete': !active}]" type="submit">
            {{ active ? t('actions.save_to_activate') : t('actions.save_to_deactivate') }}
          </button>
        </Transition>
        <a :href="`/leaderboards/${leaderboard.id}`" class="flex items-center justify-center Btn Btn--Save mr-5">
          {{t('pages.gamification.leaderboards.view_leaderboard')}}
        </a>
        <label for="cn-active" :class="['text-lg font-bold', {'text-danger': !active, 'text-success': active}]">
          {{ t('pages.gamification.leaderboards.title.s') }} {{ !active ? t('global.not') : '' }} {{ t('global.active.sf') }}
        </label>
        <label for="cn-active" class="Switch">
          <input v-model="params.active" id="cn-active" type="checkbox">
          <span class="Slider round"></span>
        </label>
      </div>
    </div>

    <form class="Form--Boxed mb-4">
      <div class="EditSection__Infos">
        <div class="grid grid-cols-3 gap-3">
          <div class="field">
            <label for="name" class="inline-block text-lg">{{ t('pages.prizes.name') }}</label>
            <input v-model="params.name" id="name" class="mb-4" type="text" placeholder="Nome" aria-label="Nome" />
          </div>
          <div class="field col-span-3">
            <label for="description" class="inline-block text-lg">{{ t('pages.prizes.description') }}</label>
            <textarea v-model="params.description" id="description" class="mb-4" placeholder="Descrizione" aria-label="Descrizione"></textarea>
          </div>
          <!-- <div class="field Collapse">
            <label for="contestant" class="inline-block text-lg">{{ t('pages.gamification.leaderboards.contestants') }}</label>
            <select v-model="params.contestant" id="contestant" class="mb-4" aria-label="Partecipante">
              <option value="all">TUTTI</option>
              <option value="users">UTENTI</option>
              <option value="group">GRUPPO</option>
            </select>
          </div> -->
          <div class="Collapse mb-4">
            <button class="Collapse__Button" @click.prevent="toggleCollapse('dates')">
              <span>{{t('pages.course.settings_dates')}}</span>
              <svg height="18" width="18" class="fill-current"> 
                <use :xlink:href="`#icon-cheveron-${collapse.dates ? 'up' : 'down'}`"></use>
              </svg>
            </button>
            <div v-show="collapse.dates" class="Collapse__Content">
              <div class="my-4">
                <label class="" for="">
                  {{ t('actions.select_date_start') }}
                  <!-- <span  :content="t('pages.course.start_date.hint')" v-tippy class="Btn--Tooltip ml-2 mr-2 ">
                      <svg height="18" width="18" class="fill-current"> 
                        <use xlink:href="#icon-information-outline"></use>
                      </svg>
                    </span> -->
                </label>
                <datetime 
                  :placeholder="t('actions.select_date')"
                  type="datetime"
                  v-model="params.start_date"
                  class="mb-2 max-w-xl">
                </datetime>
              </div>
              <div class="my-4">
                <label class="" for="">
                  {{ t('actions.select_date_end') }}
                  <!-- <span  :content="t('pages.course.end_date.hint')" v-tippy class="Btn--Tooltip ml-2 mr-2 ">
                      <svg height="18" width="18" class="fill-current"> 
                        <use xlink:href="#icon-information-outline"></use>
                      </svg>
                    </span> -->
                </label>
                <datetime 
                  :placeholder="t('actions.select_date')"
                  type="datetime"
                  v-model="params.end_date"
                  class="mb-2 max-w-xl">
                </datetime>
              </div>
            </div>
          </div>
          <div class="field flex items-center ml-5">
            <label for="anonymity" class="inline-block text-lg mr-2 mt-2">{{ t('pages.gamification.leaderboards.anonymity.name') }}</label>
            <input v-model="params.anonymity" id="anonymity" type="checkbox">
          </div>
        </div>
        <div class="flex justify-end">
          <button @click.prevent="updateLeaderboard(params)" class="Btn Btn--Save ml-2" type="button">
            {{ t('pages.gamification.leaderboards.update_leaderboard') }}
          </button>
        </div>
      </div>
    </form>
    <div class="Form--Boxed mb-4">
      <div class="field">
        <label for="cn-lang" class="text-lg font-bold mb-2">{{ t('global.language') }}</label>
        <select v-model="selectedLang" id="cn-lang" class="Select mb-4" aria-label="Lang">
          <option v-for="lang in languages" :key="lang.code" :value="lang.code">{{ lang.code }}</option>
        </select>
      </div>
      <div class="EditSection__Infos" v-for="translation in translations" :key="translation.language">
        <div v-if="selectedLang === translation.language">
          <div class="field">
            <label for="b-name" class="text-lg">{{ t('pages.prizes.name') }}</label>
            <input v-model="translation.name" id="b-name" class="mb-4" type="text" placeholder="" aria-label="Nome" />
          </div>
          <div class="field">
            <label for="b-desc" class="text-lg">{{ t('pages.prizes.description') }}</label>
            <input v-model="translation.description" id="b-desc" class="mb-4" type="text" placeholder="" aria-label="Description" />
          </div>
          <div class="flex justify-end">
            <button @click.prevent="updateTranslation(translation)" class="Btn Btn--Save" type="button">
              {{ t('actions.save') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <form class="Form--Boxed mb-4">
      <div class="EditSection__Infos">
        <div class="field col-span-3">
          <div class="mb-4 flex items-center justify-between">
            <h4>{{ t('pages.gamification.leaderboards.leaderboard_badges') }}</h4>
            <div>
              <button type="button" @click="selectAllBadges" class="Btn Btn--Success">{{ t('actions.select_all') }}</button>
              <button type="button" @click="removeAllBadges" class="Btn Btn--Delete">{{ t('actions.remove_all') }}</button>
            </div>
          </div>

          <div class="flex flex-1 mb-4">
            <input v-model="badgeSearch" type="text" :placeholder="t('pages.courses.search_undefined')" class="flex-1 mr-2" @keyup.enter="getBadgesFromSearch" />
            <button type="button" @click="getBadgesFromSearch" class="Btn Btn--Save">{{ t('actions.search') }}</button>
          </div>

          <div v-if="loadingBadges" class="LoadingBox mt-4 text-center">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          
          <div v-else-if="badges.length > 0">
            <ul class="List--Lined List--Lined--Thin">
              <li v-for="badge in pageOfBadges" :key="badge.id" class="flex items-center justify-between">
                <span>{{ badge.name }}</span>
                <button type="button" class="flex items-center" @click="toggleBadgeSelection(badge)">
                  <span class="mr-2" :class="badge.selected ? 'text-success' : 'text-warning'">
                    {{ badge.selected ? 'Selezionato' : 'Non selezionato' }}
                  </span>
                  <span :class="badge.selected ? 'Btn Btn--CheckStyle Btn--CheckStyle--Checked' : 'Btn Btn--CheckStyle Btn--CheckStyle--UnChecked'">
                    <svg height="16" width="16" class="fill-current">
                      <use xlink:href="#icon-checkmark"></use>
                    </svg>
                  </span>
                </button>
              </li>
            </ul>

            <div class="text-center mt-2">
              <jw-pagination :items="badges" @changePage="onChangeBadgePage" :labels="customLabels" class="PaginationList"></jw-pagination>
            </div>
          </div>
          
          <div v-else class="mt-4 text-center">
            <p>{{ t('pages.courses.no_badges') }}</p>
          </div>
        </div>
      </div>

      <div class="flex justify-end">
        <button @click.prevent="saveLeaderboardWithBadges" class="Btn Btn--Save" type="button">
          {{ t('actions.save') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import LeaderboardBadge from '../../../api/leaderboardBadge';
import api from '../../../api'
import BackButton from '../../commons/backButton'
import uploadMedia from '../../commons/uploadMedia'
import { global as EventBus } from '../../../js/utils/eventBus'

export default {
  name: 'AdminLeaderboardEdit',
  props: {
    leaderboard: Object,
    languages: Array
  },
  components: {
    BackButton,
    uploadMedia
  },
  data() {
    return {
      collapse: {
        dates: false,
      },
      params: {
        id: this.leaderboard.id || '',
        name: this.leaderboard.name || '',
        description: this.leaderboard.description || '',
        contestant: this.leaderboard.contestant || 'all',
        active: this.leaderboard.active || false,
        start_date: this.leaderboard.start_date || '',
        end_date: this.leaderboard.end_date || '',
        anonymity: this.leaderboard.anonymity || false,
        selected_badges_ids: [],
      },
      active: this.leaderboard.active,
      initialActive: this.leaderboard.active,
      loading: false,
      badgeSearch: '',
      badges: [],
      selectedBadges: [],
      addedBadges: [],
      removedBadges: [],
      pageOfBadges: [],
      totalPages: 1,
      page: 1,
      loadingBadges: false,
      customLabels: {
        previous: '«',
        next: '»',
      },
      translations: this.leaderboard.translations || [],
      selectedLang: 'it',
    }
  },
  mounted() {
    console.log(this.translations);
    console.log('Leaderboard props ricevute:', this.leaderboard);
    console.log('Parametri iniziali:', this.params);
    this.params.start_date = this.formatDateForInput(this.leaderboard.start_date);
    this.params.end_date = this.formatDateForInput(this.leaderboard.end_date);
    this.loadAllBadges();
    this.loadSelectedBadges();
  },
  methods: {
    async updateTranslation(translation) {
      const target = event.currentTarget;
      EventBus.emit('loader:local:start', { elem: target });
      try {
        await api.Leaderboard.updateTranslation(this.leaderboard.id, {
          translation_id: translation.id,
          name: translation.name,
          description: translation.description,
        });
        this.$toastr.s(this.t('actions.saved'));
      } catch (err) {
        this.$toastr.e(this.t('actions.error'));
      } finally {
        EventBus.emit('loader:local:end', { elem: target });
      }
    },
    async loadAllBadges() {
      try {
        const response = await api.Leaderboard.getAllBadges();
        this.badges = response;
        const selectedBadges = await LeaderboardBadge.getSelectedBadges(this.leaderboard.id);
        const selectedBadgeIds = selectedBadges.map(badge => badge.id);
        this.badges = this.badges.map(badge => ({
          ...badge,
          selected: selectedBadgeIds.includes(badge.id)
        }));
        this.selectedBadges = this.badges.filter(badge => badge.selected);
        console.log("Badge selezionati inizialmente:", this.selectedBadges);
      } catch (error) {
        console.error("Errore durante il caricamento dei badge:", error);
      }
    },

    async loadSelectedBadges() {
      if (!this.leaderboard || !this.leaderboard.id) {
        console.log("Leaderboard non trovata o ID mancante.");
        return;
      }
      try {
        const selectedBadges = await LeaderboardBadge.getSelectedBadges(this.leaderboard.id);
        console.log('Selected badges received:', selectedBadges);
        if (Array.isArray(selectedBadges)) {
          this.selectedBadges = selectedBadges.map(badge => badge.id);
          this.markSelectedBadges();
        } else {
          console.error("Errore: selectedBadges non è un array", selectedBadges);
        }
      } catch (error) {
        console.error("Errore durante il caricamento dei badge selezionati:", error);
      }
    },

    markSelectedBadges() {
      this.badges = this.badges.map(badge => ({
        ...badge,
        selected: this.selectedBadges.includes(badge.id),
      }));
    },

    toggleBadgeSelection(badge) {
      if (badge.selected) {
        this.removeBadge(badge);
      } else {
        this.addBadge(badge);
      }
    },

    addBadge(badge) {
      badge.selected = true;
      this.selectedBadges.push(badge);
      this.params.selected_badges_ids.push(badge.id);

      this.removedBadges = this.removedBadges.filter(obj => obj.id !== badge.id);
    },

    removeBadge(badge) {
      badge.selected = false;
      this.selectedBadges = this.selectedBadges.filter(b => b.id !== badge.id);
      this.params.selected_badges_ids = this.params.selected_badges_ids.filter(id => id !== badge.id);
      if (!this.addedBadges.some(item => item.id === badge.id)) {
        this.removedBadges.push(badge);
      }
    },

    async saveLeaderboardWithBadges() {
      try {
        console.log("Badge selezionati attualmente:", this.selectedBadges);
        const newBadgeIds = this.selectedBadges
          .map(badge => badge.id)
          .filter(id => id !== undefined && id !== null);
        const selectedBadges = await LeaderboardBadge.getSelectedBadges(this.leaderboard.id);
        const existingBadgeIds = selectedBadges
          .map(badge => badge.id)
          .filter(id => id !== undefined && id !== null);
        console.log("Existing Badge IDs (prima della modifica):", existingBadgeIds);
        console.log("New Badge IDs (dopo la modifica):", newBadgeIds);
        const badgeIdsToRemove = existingBadgeIds.filter(badgeId => !newBadgeIds.includes(badgeId));
        console.log("Badge IDs da rimuovere:", badgeIdsToRemove);
        const badgeIdsToAdd = newBadgeIds.filter(badgeId => !existingBadgeIds.includes(badgeId));
        console.log("Badge IDs da aggiungere:", badgeIdsToAdd);
        if (badgeIdsToRemove.length > 0) {
          await LeaderboardBadge.removeBadges(this.leaderboard.id, badgeIdsToRemove);
        }
        if (badgeIdsToAdd.length > 0) {
          await LeaderboardBadge.saveBadges(this.leaderboard.id, badgeIdsToAdd);
        }
        this.$toastr.s(this.t('actions.saved'));
      } catch (error) {
        this.$toastr.e(this.t('actions.error'));
        console.error("Errore durante il salvataggio dei badge:", error);
      }
    },

    getBadgesFromSearch() {
      this.page = 1;
      this.getBadges();
    },

    selectAllBadges() {
      this.badges.forEach(badge => {
        if (!badge.selected) {
          this.addBadge(badge);
        }
      });
    },

    removeAllBadges() {
      this.badges.forEach(badge => {
        if (badge.selected) {
          this.removeBadge(badge);
        }
      });
    },
    onChangeBadgePage(pageOfBadges) {
      this.pageOfBadges = pageOfBadges;
    },

    isActiveChanged() {
      return this.active !== this.leaderboard.active
    },
    async updateActive() {
      try {
        const res = await api.Leaderboard.updateLeaderboard(this.leaderboard.id, { active: this.active });
        this.leaderboard.active = res.data.active;
        this.initialActive = res.data.active;
        this.$toastr.s(this.t('actions.saved'));
      } catch (err) {
        this.$toastr.e(this.t('actions.error'));
        console.error('Errore durante l\'aggiornamento dello stato:', err);
      }
    },
    async updateLeaderboard(params) {
      const target = event.currentTarget;
      EventBus.emit('loader:local:start', { elem: target }); 
      this.loading = true;
      try {
        const response = await api.Leaderboard.updateLeaderboard(this.leaderboard.id, params);
        console.log("Risposta positiva:", response);
        this.$toastr.s(this.t('actions.saved'));
      } catch (error) {
        console.error("Errore nella richiesta:", error);
        this.$toastr.e(this.t('actions.error'));
      } finally {
        EventBus.emit('loader:local:end', { elem: target });
        this.loading = false;
      }
    },
    formatDateForInput(date) {
      if (!date) return '';
      const d = new Date(date);
      const year = d.getFullYear();
      const month = ('0' + (d.getMonth() + 1)).slice(-2);
      const day = ('0' + d.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    },
    toggleCollapse(section) {
      console.log('Toggle section:', section);
      this.collapse[section] = !this.collapse[section];
    },
    // getPostUploadUrl() {
    //   return `/admin/gamification/leaderboards/${this.leaderboard.id}/upload`;
    // },
  }
}
</script>

<style lang="scss">
#cn-lang {
  text-transform: uppercase;
  max-width: 200px;
}
</style>